.promo-container {
  max-width: 800px;
  text-align: center;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #1a202c;
}

.subtitle {
  font-size: 1.1rem;
  color: #4a5568;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.cta-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.cta-button {
  display: inline-block;
  background-color: #4299e1;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 1rem 2rem;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  z-index: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
  background-color: #3182ce;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.promo-tag {
  margin-top: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  text-align: center;
  background-color: #faf089;
  color: #744210;
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  position: relative;
  z-index: 2;
}

.promo-tag:hover {
  transform: scale(1.05);
}

.promo-icon {
  margin-right: 0.5rem;
}

.promo-text {
  word-wrap: break-word;
  hyphens: auto;
}

@media (max-width: 768px) {
  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }

  .promo-tag {
    font-size: 0.8rem;
  }
}

.gradient-text {
  background: linear-gradient(45deg, #2b59ff 0%, #bb2bff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.highlight-text {
  background: rgba(43, 89, 255, 0.08);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  display: inline-block;
}

.pulse-animation {
  animation: pulse 2s infinite;

  &:hover {
    animation: none;
    transform: scale(1.05);
    transition: transform 0.3s ease;
  }
}

.shine-effect {
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: rotate(30deg);
    animation: shine 3s infinite;
  }
}

.bounce {
  display: inline-block;
  animation: bounce 1s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(43, 89, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(43, 89, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(43, 89, 255, 0);
  }
}

@keyframes shine {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.banner-style-1 {
  .banner-content {
    .animated-gradient-text {
      background: linear-gradient(
        90deg,
        #2b59ff,
        #4c6fff,
        #6b47ed,
        #4c6fff,
        #2b59ff
      );
      background-size: 200% auto;
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      animation: shine 8s linear infinite;
      font-weight: 700;
      text-shadow: 0 2px 15px rgba(43, 89, 255, 0.2);

      &:hover {
        animation-duration: 2s;
      }
    }

    .animated-gradient-subtitle {
      background: linear-gradient(
        90deg,
        #2b59ff,
        #4c6fff,
        #6b47ed,
        #4c6fff,
        #2b59ff
      );
      background-size: 200% auto;
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      animation: shine 12s linear infinite;
      font-weight: 600;

      &:hover {
        animation-duration: 3s;
      }
    }

    .cta-container {
      .axil-btn {
        display: inline-flex;
        align-items: center;
        gap: 10px;
        overflow: hidden;
        position: relative;

        .btn-icon {
          font-size: 1.2rem;
          animation: rocketShake 2s ease-in-out infinite;
        }

        &:hover .btn-icon {
          animation: rocketBlast 1s ease-in-out infinite;
        }
      }

      .promo-tag {
        .promo-text {
          font-weight: 600;
        }
      }
    }
  }
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

@keyframes rocketShake {
  0%,
  100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
}

@keyframes rocketBlast {
  0% {
    transform: translateX(0) rotate(0deg);
  }
  50% {
    transform: translateX(3px) rotate(5deg);
  }
  100% {
    transform: translateX(0) rotate(0deg);
  }
}

.banner-style-1 {
  .title,
  .subtitle {
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
    }
  }

  .promo-tag {
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-3px) scale(1.02);
    }
  }
}

.pulse-animation {
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: inherit;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
}
