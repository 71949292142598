.floating-update-badge {
  position: fixed;
  bottom: 80px;
  left: 30px;
  z-index: 1000;
  animation: badgeFloat 3s ease-in-out infinite;

  .badge-content {
    background: rgba(255, 255, 255, 0.95);
    padding: 12px 24px;
    border-radius: 50px;
    box-shadow: 0 4px 15px rgba(43, 89, 255, 0.2);
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 1rem;
    transition: all 0.3s ease;
    border: 1px solid rgba(43, 89, 255, 0.1);
    cursor: pointer;

    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 6px 20px rgba(43, 89, 255, 0.3);

      .sync-icon {
        animation: spin 1s linear infinite;
      }

      .gradient-text {
        animation-play-state: running;
      }
    }

    .sync-icon {
      font-size: 1.1rem;
      color: #2b59ff;
      animation: spin 2s linear infinite;
    }

    .gradient-text {
      background: linear-gradient(
        90deg,
        #2b59ff,
        #4c6fff,
        #6b47ed,
        #4c6fff,
        #2b59ff
      );
      background-size: 200% auto;
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      animation: shine 3s linear infinite;
      animation-play-state: paused;
      white-space: nowrap;
      font-weight: 700;
    }
  }

  .badge-glow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
      circle at center,
      rgba(43, 89, 255, 0.4) 0%,
      transparent 70%
    );
    filter: blur(15px);
    z-index: -1;
    opacity: 0.5;
    animation: glowPulse 2s ease-in-out infinite alternate;
  }
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

@keyframes badgeFloat {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes glowPulse {
  0% {
    opacity: 0.3;
    transform: scale(0.95);
  }
  100% {
    opacity: 0.6;
    transform: scale(1.05);
  }
}

// Media query for mobile devices
@media (max-width: 768px) {
  .floating-update-badge {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

    .badge-content {
      padding: 10px 20px;
      font-size: 0.9rem;
    }
  }
}
